@import "https://code.getmdl.io/1.3.0/material.green-light_green.min.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css";

* {
	padding: 0;
	margin: 0;
}

/* Application wide styling for the app frame. */
.mpkApplication {
	height: 100vh;
	width: 100vw;
}


.icon_bs_left {
	color: white;
	float: left;
	margin: .25rem;
	padding: .25rem;
	width: min-content;
}
.icon_bs_right {
	color: white;
	float: right;
	margin: .25rem;
	padding: .25rem;
	width: min-content;
}

.mpkFooter {
	width: 100vw;
	display: grid;
	grid-template-columns: 2fr;
	grid-template-rows: 1fr 1fr;
	justify-items: normal;
	justify-self: center;
	padding-top: 0px;
	padding-bottom: 0px;
}
.footerItem {
	text-align: center;
	margin-bottom: 0px;
}
.footerItemGrid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-items: center;
}
.footerItemGridItem {
	color: white;
}

.page {
	height: 100vh;
	width: 100vw;
}
.header {
	height: min-content;
}

.footer {
	height: min-content;
	color: white;
	min-width: min-content;
	text-align: center;
}

.sticky-bottom {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: green;
	color: white;
	text-align: center;
}


a {
	border-radius: 20px;
}


input[type="text"],
input[type="submit"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="time"],
input[type="tel"],
select,
	.mpkButton, 
	.contact form input, 
	.contact form textarea {
	width: 100%;
	padding: 1rem;
	border: 1px solid #00833b;
	border-radius: 25px;
}


input[type="text"] {
	border: 2px solid darkgreen;
}


button[type="submit"] {
	width: 100%;
	padding: 1rem;
	border: 1px solid #00833b;
	border-radius: 25px;	
}


label {
	color: black;
	float: left;
	font-weight: 600;
}


.form-group .input-group textarea {
	border: 2px solid darkgreen;
	border-radius: 25px;
}


/* SCROLL BAR STYLES */
/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: white white;
}  
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar { width: 1px; }
*::-webkit-scrollbar-track { background: white; }
*::-webkit-scrollbar-thumb { border-radius: 1px; }






.accordion-label {
	border: 1px solid black;
	border-radius: .5rem;
	margin: .5rem;
	/*padding: 1rem;*/
}
.accordion-item {
	padding: .25rem;
}
.accordion-item-food-item {
	display: grid;
	grid-template-columns: 10fr 2fr;
	grid-template-rows: 1fr;
	margin-bottom: 0px;
}
.accordion-item-food-item-left {
	background-color: white;
	border-top-left-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
	color: black;
	font-size: large;
	margin-bottom: 0px;
	padding: 0.75rem;
}
.accordion-item-food-item-right {
	align-items: center;
	background-color: green;
	border-top-right-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	font-size: large;
	justify-content: center;
	margin-bottom: 0px;
}



.mpk-list-item {
	display: grid;
	grid-template-columns: 10fr 2fr;
	grid-template-rows: 1fr;
	margin-bottom: 0px;
	width: 100%;
}
.mpk-list-item-left {
	background-color: white;
	border-top-left-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
	color: black;
	font-size: large;
	margin-bottom: 0px;
	padding: 0.75rem;
	text-align: left;
}
.mpk-list-item-right {
	align-items: center;
	background-color: green;
	border-top-right-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	font-size: large;
	justify-content: center;
	margin-bottom: 0px;
	text-align: right;
}


/* Handle the bootstrap toast width and height */
#toastElement {
	color: white;
	height: 3rem;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}




.circleButton {
	color: white;
	height: 60px;
	max-width: 60px;
	min-width: 60px;
	border-radius: 50px;
}

























.ui_page {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: min-content auto min-content;
}


/* quickstart_body */
.ui_body {
	background: rgb(30, 178, 64);
	color: white;
	height: 100vh;
	margin-bottom: 15rem;
	position: relative;
}


/* #qs_verbage */
#ui_verbage {
	height: fit-content;
	padding: 1rem;
}


/* .qs_outer_interface */
.ui_outer { 
	margin-top: 20px;
	padding: 1rem; 
	min-height: -webkit-fill-available; 
	background-color: white;
	border-top-left-radius: 50px;
	border-top-right-radius: 50px;
}


/* #qs_inner_interface */
#ui_inner {
	text-align: center; margin-bottom: 50px;
}






























































* {
	box-sizing: border-box;
}

div {
	margin-bottom: .5rem;
}

.container {
	max-width: 1170px;
	margin-left: auto;
	margin-right: auto;
	min-width: 320px;
}

.brand {
	text-align: center;
}

.brand span {
	color: #fff;
}

.wrapper {
	box-shadow: 0 0 10px 0 rgba(72,94,116,0.7);
}

.wrapper > *{
	padding: 1em;
}

.company-info {
	background: #d6ffc9;
	text-align: center;
}

.company-info h3, .company-info ul {
	text-align: center;
	margin: 0 0 1rem 0;
}

.contact {	
	/*border: 1px solid green;*/
	border-radius: 20px;
}

.contact h3 {	
	text-align: center;
}

/* FORM STYLES */
.contact form {
	display: flex;
	flex-direction: column;
}

.contact form p {
	margin:0;
}

.contact form .full {
	grid-column: 1 / 3;
}

.contact form button, .contact form input, .contact form textarea {
	width: 100%;
	padding: .75em;
	border: 1px solid #00833b;
	border-radius: 20px;
}

.contact form button {
	border: 0;
	text-transform: uppercase;
}

.contact form button:hover, .contact form button:focus {
	background: #92e7a7;
	color: #fff;
	outline: 0;
	transition: background-color 2s ease-out;
}


/* LARGE SCREENS */
@media(min-width: 700px) {
	.wrapper {
		display: grid;
		grid-template-columns: 1fr 2fr;
	}

	.wrapper > * {
		padding: 2em;
	}

	.company-info, h3, .company-info ul, .brand {
		text-align: left;		
	}
	.contact form {	
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
	}	
	.contact form button, .contact form input, .contact form textarea {
		width: 100%;
		padding: .75em;
		border: 1px solid #00833b;
		border-radius: 20px;
	}
}
















